@use '../resources/colors';

html {
  --vaimo-animation-in: cubic-bezier(0, 0, 0.2, 1);
  --vaimo-animation-out: cubic-bezier(0.4, 0, 1, 1);

  &[data-theme='sw'] {
    --brand-color--primary: #{colors.$color--alizarin-crimson};
    --brand-color--quaternary: #{colors.$color--gray-lightest};
    --brand-color--secondary: #{colors.$color--white};
    --brand-color--tertiary: #{colors.$color--mine-shaft};
  }

  &[data-theme='ww'] {
    --brand-color--primary: #000;
    --brand-color--quaternary: #d2d755;
    --brand-color--secondary: #dc4405;
    --brand-color--tertiary: #0077c8;
  }
}
