@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm' as *;
@use 'normalize';
@use '@vaimo-int/aem-pwa-common-components/dist/styles/hhecomm-fonts';
@use '../utils/helpers';
@use '../resources/colors' as color;
@use '../resources/fonts' as font;
@use '../resources/mixins' as mixin;
@use '@vaimo-int/aem-pwa-common-components/dist/styles/vars';
@import 'legacy-theme';
@import 'keyframes';

/*
 * Lock the window's scroll position while an overlay is active.
 */
html[data-scroll-lock='true'],
html[data-scroll-lock='true'] body {
    height: 100vh;
    overflow-y: hidden;
    position: sticky; // Fix for Firefox
}

@supports (-webkit-touch-callout: none) {
    html[data-scroll-lock='true'],
    html[data-scroll-lock='true'] body {
        /* The hack for Safari */
        height: -webkit-fill-available;
    }
}

body {
    background-color: color.$color--white;
    font-family: font.$font-family--base-global;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-variant-ligatures: none;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

// Global overrides
.grecaptcha-badge {
    border-radius: 0.25rem;
    box-shadow: none !important;
    overflow: hidden;
}

.adyen-checkout__input {
    border-color: color.$border-color--black !important;
    border-radius: 0.25rem !important;
    caret-color: color.$text-color--primary !important;

    &:focus {
        border-color: color.$border-color--black !important;
        box-shadow: none !important;
    }
}

.iconSuccess {
    color: color.$text-color--green;
}

// Salesforce chat button comes from external script,
// thus we have to override styles specifically and globally
.embeddedServiceHelpButton {
    z-index: 2 !important;
}

.embeddedServiceSidebarMinimizedDefaultUI .messageContent .minimizedImage .uiImage,
.embeddedServiceSidebarMinimizedDefaultUI .messageContent .minimizedImage img {
    border-radius: 0;
}

.embeddedServiceHelpButton .helpButton {
    @media only screen and (min-width: 48em) {
        bottom: 12px !important;
    }
}

.embeddedServiceHelpButton .helpButton .uiButton {
    background-color: color.$color--alizarin-crimson !important;
    border-radius: 4rem !important;
    font-family: font.$font-family--base-global !important;
    justify-content: center !important;
    min-width: 1rem;
    padding: 0 !important;
    width: 46px;

    &:focus,
    &:hover {
        &::before {
            border-radius: 4rem !important;
        }
    }

    &:focus {
        outline: 1px solid color.$color--alizarin-crimson !important;
    }

    .embeddedServiceIcon {
        display: flex !important;
        margin: 0;

        &::before {
            font-size: 1.6rem;
        }
    }

    .helpButtonLabel {
        font-size: 0;
        height: 100%;
        left: 0;
        line-height: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

// Yotpo review text (external markup & styles)
.yotpo .content-review {
    word-break: break-word;
}

.slick-slider {
    .slick-track {
        display: flex !important;
    }

    .slick-slide {
        height: inherit !important;

        > div {
            height: 100%;
            padding: 0 0.5rem;

            @include vars.mq-small {
                padding: 0 0.2rem;
            }

            > div {
                height: 100%;
            }
        }
    }
}

// Customize FitAnalytics button
.fitanalytics__button {
    margin: 0 0 0 auto !important;

    .fitanalytics__button-text {
        align-items: center !important;
        color: var(--color--gray-800) !important;
        font-size: 0.875rem !important;
        font-weight: 400 !important;
        text-transform: none !important;
    }

    .fitanalytics__button-image {
        display: none !important;
    }
}
