// Keyframes handling is explained in the README.md file.

/*
/*  Button ripple
*/

@keyframes useRippleAnimation {
  to {
    opacity: 0;
    transform: scale(15);
  }
}

/*
/*  Think of clicking a radio button or a checkbox, and seeing this animation.
*/

@keyframes tick {
  0% {
    transform: scale(0);
  }

  90% {
    transform: scale(1.4);
  }

  100% {
    transform: scale(1);
    visibility: visible;
  }
}

/*
/*  A simple spinner
*/

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*
/*  A Magento Venia concept store implemented spinner, but without the scale effect.
*/

@keyframes indicatorNoScaleRotate {
  0% {
    transform: rotate(-540deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

/*
/*  Pulse animation for Add to Wishlist Button
*/

@keyframes pulseHeart {
  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(0.8);
  }

  to {
    transform: scale(1);
  }
}

/*
/*  Fade In effect.
*/

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
/*  Shakes your element left and right
/*
/*  Usage:
/*
/*  :global {
/*    animation: shake-animation 4.72s ease infinite;
/*  }
/*
/*  Your hook, that handles the event should unmount after 500ms
/*
/*  @see useProductFullDetail
/*  src/Vaimo/hellyhansen-ui/overrides/@magento/peregrine/lib/talons/ProductFullDetail/useProductFullDetail.js
/*
/*  Please move this talon's shake animation into a separate hook for easier implementation if you'll want to reuse this animation
/*
*/

@keyframes shake-animation {
  0% {
    transform: translate(0, 0);
  }

  1.78571% {
    transform: translate(0.25rem, 0);
  }

  3.57143% {
    transform: translate(0, 0);
  }

  5.35714% {
    transform: translate(0.25rem, 0);
  }

  7.14286% {
    transform: translate(0, 0);
  }

  8.92857% {
    transform: translate(0.25rem, 0);
  }

  10.71429% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
